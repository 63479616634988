<template>
  <div class="Order">
    <!-- 头部 -->
    <div style="position: fixed; left: 0; right: 0; background: #f0f6f9">
      <div class="topbar" >
        <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
        <div class="t-title">{{ $t("點數查詢") }}</div>
      </div>
      <div class="o1">
        <div class="o11">{{ $t("剩餘點數") }}</div>
        <div class="o12">{{ orderList.point }}</div>
      </div>
      <div class="o2"></div>
      <div class="content-title" v-if="orderList.orders">
        <div class="left">
          <p class="p1">{{ $t("充值記錄") }}</p>
          <p class="p2">
            {{ $t("本月累計儲值金額") }}<span>{{ orderList.amountMonth }}</span>
          </p>
        </div>
        <div class="right">
          <p>
            {{ $t("本月累計儲值點數")
            }}<span>{{ Math.ceil(orderList.pointMonth) }}{{ $t("點") }}</span>
          </p>
        </div>
      </div>
      <div class="o3"></div>
    </div>

    <div class="content">
      <div class="content-list">
        <ul v-if="orderList.orders" v-scroll="onScroll">
          <li v-for="(item, index) in items" :key="index">
            <div class="left">
              <p class="p1">{{ item.createTime }}</p>
              <p class="p2">{{ item.payName }}</p>
              <p class="p3">{{ $t("訂單號") }}：{{ item.order_id }}</p>
            </div>
            <div class="right">
              <p v-if="item.status == 0" class="p1" style="color: #B57E13;background: #FDF1CB;">
                {{ $t("未支付") }}
              </p>
              <p v-if="item.status == 1" class="p1" style="color: #3FC61D;background: #D1FAEC;">
                {{ $t("已完成") }}
              </p>
              <p v-if="item.status == 2" class="p1" style="color: #B57E13;background: #FDF1CB;">
                {{ $t("未完成") }}
              </p>
              <p class="p2">{{ item.real_point }}{{ $t("點") }}</p>
              <p class="p3">{{ $t("儲值金額") }}：{{ item.amount }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 订单数据
      orderList: [],
      searchData: {
        pageIndex: 1,
        pageSize: 10,
      },
      totalPageCount: 0,
      items: [],
      isAchiveBottom: false, //滚动条是否到底部标志
    };
  },
  created() {
    // 获取订单数据
    this.getOrderList();
  },

  methods: {
    // 获取订单数据
    getOrderList() {
      this.$getpay("/api/Home/Orders", {
        pageIndex: this.searchData.pageIndex,
        pageSize: this.searchData.pageSize,
      }).then((res) => {
        this.orderList = res.data;
        if (this.items) {
          this.items = this.items.concat(res.data.orders.items);
        } else {
          this.items = res.data.orders.items;
        }
        var count = Math.ceil(
          res.data.orders.totalCount / this.searchData.pageSize
        );
        this.totalPageCount = count == 0 ? 1 : count;
      });
    },
    onScroll() {
      // 滑动高度
      var scrollTop = document.documentElement.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (
        scrollTop + windowHeight > scrollHeight - 20 &&
        !this.isAchiveBottom
      ) {
        this.isAchiveBottom = true;
        //延时触发数据加载
        setTimeout(() => {
          if (this.searchData.pageIndex + 1 <= this.totalPageCount) {
            this.searchData.pageIndex++;
          }
          this.isAchiveBottom = false;
        }, 500);
      }
    },
  },
  watch: {
    searchData: {
      handler() {
        this.getOrderList();
      },
      deep: true, //深度监听对象属性
    },
  },
};
</script>

<style lang="scss" scoped>
.Order {
  background-color: #F0F6F9;
  .topbar {
    display: flex;
    align-items: center;
    text-align: left;
    height: 1.17333rem;
    position: relative;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .t-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-size: 18px;
      text-align: center;
      line-height: 1;
      color: #485573;
    }
  }

  .o1{
    margin-top: 1.04rem;
    text-align: left;
    padding: 0 0.64rem;
    display: flex;
    align-items: flex-end;
    .o11{
      font-size: 0.37333rem;
    line-height: 0.53333rem;
    color: #485573;
    }
    .o12{
      font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 0.58667rem;
    line-height: 0.8rem;
    text-align: right;
    color: #FF5285;
    }
  }
  .o2{
    margin: 0.21333rem 0.64rem 0.37333rem;
    opacity: 0.5;
    border: 0.02667rem solid #95B6C7;
  }

  p {
    padding: 20px 24px 0;
    text-align: left;
    font-size: 18px;
    color: #333333;
    margin: 0;
  }
  h3 {
    padding: 0 24px;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    color: #f84279;
  }
  .content-title {
    padding: 0 0.64rem;
    display: flex;
    justify-content: space-between;
    .left {
      .p1 {
        padding: 0;
        font-size: 14px;
        color: #485573;
      }
      .p2 {
        padding: 0;
        font-size: 12px;
        color: #666666;
        span {
          display: inline-block;
          font-weight: 700;
          font-size: 16px;
          color: #F84E84;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: end;
      p {
        padding: 0;
        font-size: 12px;
        color: #666666;
        span {
          display: inline-block;
          font-weight: 700;
          font-size: 16px;
          color: #F84E84;
        }
      }
    }
  }
  .o3{
    margin: 0.21333rem 0.64rem 0.37333rem;
    opacity: 0.5;
    border: 0.02667rem solid #95B6C7;
  }
  .content {
    padding: 5.6rem 0 0;

    .content-list {
      padding: 0px 24px;
      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        li {
          padding: 14px 10px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          background: #FFFFFF;
box-shadow: 0px 8px 15px rgba(207, 222, 228, 0.3);
border-radius: 10px;
margin-bottom: 20px;

          p {
            padding: 0;
          }
          .left {
            .p1 {
              font-size: 14px;
line-height: 15px;
color: #95B6C7;
            }
            .p2 {
              font-size: 16px;
color: #485573;
margin: 16px 0 14px 0;
            }
            .p3 {
              font-size: 14px;
color: #485573;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .p1 {
              text-align: center;
    font-size: 0.32rem;
    padding: 0.10667rem 0.29333rem;
    border-radius: 0.13333rem;
            }
            .p2 {
              font-weight: 700;
font-size: 0.48rem;

text-align: right;

color: #F84279;
            }
            .p3{
              font-size: 0.32rem;

text-align: right;
color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>